import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './StartFlow.css';

const StartFlow = () => {
  const [runnerName, setRunnerName] = useState('');
  const navigate = useNavigate();

  const handleRunnerSubmit = (e) => {
    e.preventDefault();
    if (runnerName.trim()) {
      navigate(`/events/new?runner=${encodeURIComponent(runnerName.trim())}`);
    }
  };

  return (
    <section className="runner-section">
      <div className="runner-form">
        <h2 className="visually-loaded">Who's Your Champion?</h2>
        <form onSubmit={handleRunnerSubmit}>
          <div className="form-group">
            <input
              id="runnerName"
              name="runnerName"
              type="text"
              value={runnerName}
              onChange={(e) => setRunnerName(e.target.value)}
              placeholder="Enter Runner's Name"
              required
            />
          </div>
          <button type="submit" className="start-button">
            Start
          </button>
        </form>
      </div>
      <footer className="security-footer">
        <p className="security-message">
          <i className="fas fa-shield-alt"></i>
          Your information is protected with industry-standard encryption and security measures
        </p>
      </footer>
    </section>
  );
};

export default StartFlow; 