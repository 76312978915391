import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../firebase/config';
import './EventActions.css';

const EventActions = ({ eventId, isPublic = true, userId, currentUserId }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleAction = async (action) => {
    setLoading(true);
    setError(null);
    try {
      switch (action) {
        case 'edit':
          navigate(`/event/${eventId}/edit`);
          break;
        case 'archive': {
          if (!window.confirm('Are you sure you want to archive this event?')) {
            break;
          }
          const eventRef = doc(db, 'events', eventId);
          await updateDoc(eventRef, {
            status: 'archived',
            archivedAt: new Date().toISOString()
          });
          toast.success('Event archived successfully');
          navigate('/my-events');
          break;
        }
        case 'visibility':
          // TODO: Implement visibility toggle
          console.log('Toggle visibility:', eventId);
          break;
        case 'digital':
          // TODO: Implement digital product generation
          console.log('Generate digital product:', eventId);
          break;
        case 'physical':
          // TODO: Implement physical product generation
          console.log('Generate physical product:', eventId);
          break;
        default:
          break;
      }
    } catch (err) {
      setError('Failed to perform action. Please try again.');
      console.error('Action error:', err);
      toast.error('Failed to archive event');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="event-actions">
      <h3>Event Management</h3>
      <div className="action-buttons">
        <button 
          onClick={() => handleAction('archive')}
          disabled={loading}
          className="action-button archive"
        >
          <i className="fas fa-archive" />{' '}
          Archive Event
        </button>

        {userId === currentUserId && (
          <button 
            onClick={() => handleAction('edit')}
            disabled={loading}
            className="action-button edit"
          >
            <i className="fas fa-edit" />{' '}
            Edit Event
          </button>
        )}

        <button 
          onClick={() => handleAction('visibility')}
          disabled={loading}
          className={`action-button visibility ${isPublic ? 'public' : 'private'}`}
        >
          <i className={`fas fa-${isPublic ? 'lock' : 'globe'}`}></i>
          Make {isPublic ? 'Private' : 'Public'}
        </button>

        <button 
          onClick={() => handleAction('digital')}
          disabled={loading}
          className="action-button digital"
        >
          <i className="fas fa-file-download"></i>
          Digital Product
        </button>

        <button 
          onClick={() => handleAction('physical')}
          disabled={loading}
          className="action-button physical"
        >
          <i className="fas fa-box"></i>
          Physical Product
        </button>
      </div>

      {error && <div className="error-message">{error}</div>}
      {loading && <div className="loading-message">Processing...</div>}
    </div>
  );
};

export default EventActions; 