import React from 'react';
import './Hero.css';

const Hero = () => {
  return (
    <header className="hero">
      <h1>
        <span className="visually-loaded">Runner Roll</span>
        <noscript>Runner Roll</noscript>
      </h1>
      <p className="tagline">
        <span className="visually-loaded">Celebrate your runner's achievement with a personalized photo gift</span>
        <noscript>Celebrate your runner's achievement with a personalized photo gift</noscript>
      </p>
    </header>
  );
};

export default Hero; 