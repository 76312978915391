import React, { useState } from 'react';
import './FAQ.css';

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqs = [
    {
      question: "How does it work?",
      answer: "Simply enter your champion's name, customize their celebration card with photos, messages, and race details, then choose to download, share, or print their personalized memorabilia."
    },
    {
      question: "What types of events are supported?",
      answer: "We support all running events - from fun runs to ultramarathons. You can customize the race type and distance in your creation."
    },
    {
      question: "Can I order physical prints?",
      answer: "Yes! After designing your celebration card, you can order high-quality printed memorabilia that will be shipped directly to you."
    },
    {
      question: "How do I share my creation?",
      answer: "You can download your creation digitally, generate a shareable link, or share directly to social media platforms to celebrate your champion's achievement."
    }
  ];

  return (
    <section className="faq-section">
      <h2>Frequently Asked Questions</h2>
      <div className="faq-container">
        {faqs.map((faq, index) => (
          <div 
            key={index} 
            className={`faq-item ${activeIndex === index ? 'active' : ''}`}
            onClick={() => toggleFAQ(index)}
          >
            <div className="faq-question">
              <h3>{faq.question}</h3>
              <span className="faq-icon">{activeIndex === index ? '−' : '+'}</span>
            </div>
            <div className="faq-answer">
              <p>{faq.answer}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default FAQ; 