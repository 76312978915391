import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { db } from '../../firebase/config';
import { collection, query, where, getDocs, doc, updateDoc, getDoc } from 'firebase/firestore';
import { useAuth } from '../../context/AuthContext';
import './UserEvents.css';

const UserEvents = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [archiveConfirm, setArchiveConfirm] = useState(null);
  const [isArchiving, setIsArchiving] = useState(false);

  useEffect(() => {
    const fetchUserEvents = async () => {
      if (!user || !user.uid) {
        setError('Please log in to view your events');
        setLoading(false);
        return;
      }

      try {
        const eventsQuery = query(
          collection(db, 'events'),
          where('userId', '==', user.uid),
          where('status', '!=', 'archived')
        );
        
        const querySnapshot = await getDocs(eventsQuery);
        const eventsData = [];
        
        for (const doc of querySnapshot.docs) {
          try {
            const event = doc.data();
            if (event.photoId) {
              const photoDoc = await getDoc(doc(db, 'photos', event.photoId));
              if (photoDoc.exists()) {
                event.imageUrl = photoDoc.data().imageUrl;
              }
            }
            
            eventsData.push({
              id: doc.id,
              ...event
            });
          } catch (photoErr) {
            console.error('Error fetching photo for event:', doc.id, photoErr);
            // Continue with next event even if photo fetch fails
            eventsData.push({
              id: doc.id,
              ...doc.data()
            });
          }
        }
        
        setEvents(eventsData);
        setError(null); // Clear any previous errors
      } catch (err) {
        console.error('Error fetching events:', err);
        setError('Failed to load your events. Please try refreshing the page.');
      } finally {
        setLoading(false);
      }
    };

    fetchUserEvents();
  }, [user]);

  const handleArchive = async (eventId) => {
    setIsArchiving(true);
    try {
      // Update the event status to archived
      const eventRef = doc(db, 'events', eventId);
      await updateDoc(eventRef, {
        status: 'archived',
        archivedAt: new Date().toISOString()
      });

      // Update local state
      setEvents(events.filter(event => event.id !== eventId));
      setArchiveConfirm(null);
    } catch (err) {
      console.error('Error archiving event:', err);
      setError('Failed to archive event. Please try again.');
    } finally {
      setIsArchiving(false);
    }
  };

  if (loading) return <div className="loading">Loading your events...</div>;
  if (error) return <div className="error">{error}</div>;

  return (
    <div className="user-events-container">
      <header className="user-events-header">
        <h1>My Events</h1>
        <button 
          className="new-event-button"
          onClick={() => navigate('/')}
        >
          Create New Event
        </button>
      </header>

      <div className="events-grid">
        {events.length === 0 ? (
          <div className="no-events">
            <p>You haven't created any events yet.</p>
            <button 
              className="create-first-button"
              onClick={() => navigate('/')}
            >
              Create Your First Event
            </button>
          </div>
        ) : (
          events.map(event => (
            <div key={event.id} className="event-card">
              <div className="event-image">
                <img src={event.imageUrl} alt={`${event.runnerName}'s event`} />
                <button 
                  className="archive-button"
                  onClick={() => setArchiveConfirm(event.id)}
                  aria-label="Archive event"
                >
                  <i className="fas fa-archive"></i>
                </button>
              </div>
              <div className="event-info">
                <h3>{event.eventName}</h3>
                <p>{event.runnerName}</p>
                <p className="event-date">
                  {new Date(event.eventDate).toLocaleDateString()}
                </p>
              </div>
              <button 
                className="view-event-button"
                onClick={() => navigate(`/event/${event.id}`)}
              >
                View Event
              </button>
            </div>
          ))
        )}
      </div>

      {archiveConfirm && (
        <div className="archive-modal">
          <div className="archive-modal-content">
            <h2>Archive Event</h2>
            <p>Are you sure you want to archive this event? You can access it later in your archived events.</p>
            <div className="archive-modal-buttons">
              <button 
                className="cancel-button"
                onClick={() => setArchiveConfirm(null)}
                disabled={isArchiving}
              >
                Cancel
              </button>
              <button 
                className="confirm-archive-button"
                onClick={() => handleArchive(archiveConfirm)}
                disabled={isArchiving}
              >
                {isArchiving ? 'Archiving...' : 'Archive'}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserEvents;