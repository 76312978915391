import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { db, auth, googleProvider } from '../../firebase/config';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { signInWithPopup } from 'firebase/auth';
import { useAuth } from '../../context/AuthContext';
import Notes from '../Notes';
import EventActions from '../EventActions';
import PromoFooter from '../PromoFooter';
import './EventPreview.css';

function EventPreview({ ...props }) {
  const { eventId } = useParams();
  const location = useLocation();
  const [eventData, setEventData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [saveStatus, setSaveStatus] = useState('');
  const { user } = useAuth();
  const downloadLinkRef = useRef(null);
  const [isImageLoading, setIsImageLoading] = useState(true);

  const fetchEventData = useCallback(async () => {
    try {
      if (location.state) {
        setEventData(location.state);
        setLoading(false);
        return;
      }

      const eventDoc = await getDoc(doc(db, 'events', eventId));

      if (!eventDoc.exists()) {
        setError('Event not found');
        return;
      }

      const eventDetails = eventDoc.data();

      if (!eventDetails.photoId) {
        setError('Event photo not found');
        return;
      }

      const photoDoc = await getDoc(doc(db, 'photos', eventDetails.photoId));

      if (!photoDoc.exists()) {
        setError('Photo details not found');
        return;
      }

      setEventData({
        eventId,
        runnerName: eventDetails.runnerName,
        eventData: {
          eventName: eventDetails.eventName,
          eventDate: eventDetails.eventDate,
          eventTime: eventDetails.eventTime,
          location: eventDetails.location,
          finishTime: eventDetails.finishTime
        },
        imageUrl: photoDoc.data().imageUrl,
        photoId: photoDoc.id,
        userId: eventDetails.userId
      });
    } catch (err) {
      console.error('Error fetching event:', err);
      setError('Failed to load event details');
    } finally {
      setLoading(false);
    }
  }, [eventId, location.state]);

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchEventData();
  }, [fetchEventData]);

  const handleGoogleSignIn = async () => {
    try {
      setSaveStatus('signing-in');
      await signInWithPopup(auth, googleProvider);
    } catch (err) {
      console.error('Error signing in:', err);
      setSaveStatus('error');
    }
  };

  const handleSaveToAccount = async () => {
    if (!user || !eventData) return;

    try {
      setSaveStatus('saving');
      await updateDoc(doc(db, 'events', eventId), {
        userId: user.uid
      });
      setSaveStatus('saved');
      setEventData(prev => ({
        ...prev,
        userId: user.uid
      }));
    } catch (err) {
      console.error('Error saving event:', err);
      setSaveStatus('error');
    }
  };

  const handleShare = async () => {
    if (!eventData) return;

    try {
      if (navigator.share) {
        await navigator.share({
          title: `${eventData.runnerName}'s ${eventData.eventData.eventName} Achievement`,
          text: `Check out ${eventData.runnerName}'s achievement at ${eventData.eventData.eventName}!`,
          url: window.location.href
        });
      } else {
        await navigator.clipboard.writeText(window.location.href);
        alert('Link copied to clipboard!');
      }
    } catch (err) {
      console.error('Error sharing:', err);
    }
  };

  const handleDownload = () => {
    if (!eventData || !downloadLinkRef.current) return;

    downloadLinkRef.current.href = eventData.imageUrl;
    downloadLinkRef.current.download = `${eventData.runnerName}-${eventData.eventData.eventName}.jpg`;
    downloadLinkRef.current.click();
  };

  if (loading) return <div className="loading">Loading event details...</div>;
  if (error) return <div className="error">{error}</div>;
  if (!eventData) return <div className="error">No event data available</div>;

  const { runnerName, eventData: details, imageUrl, userId } = eventData;
  const isEventSaved = userId === user?.uid;

  return (
    <div className="event-preview">
      <header className="event-preview-header">
        <h1>{details?.eventName || 'Event'}</h1>
        <p className="venue-display">
          <i className="fas fa-map-marker-alt"></i>
          {details?.location || 'Venue to be announced'}
        </p>
        {eventData?.status === 'archived' && (
          <span className="archived-badge">
            Archived
          </span>
        )}
      </header>

      <div className="event-preview-content">
        <div className="achievement-container">
          <div className="photo-container">
            <div className="champion-frame">
              {isImageLoading && <div className="image-placeholder shimmer"></div>}
              <img
                src={imageUrl}
                alt={`${runnerName} at ${details?.eventName || 'the event'}`}
                className="event-photo"
                loading="lazy"
                onLoad={() => setIsImageLoading(false)}
                style={{
                  opacity: isImageLoading ? 0 : 1,
                  transition: 'opacity 0.3s ease'
                }}
              />
              <div className="champion-overlay"></div>
            </div>
          </div>
          <div className="info-section">
            <h2 className="achievement-title">{runnerName}</h2>
            <div className="info-grid">
              <div className="info-item">
                <label>Time</label>
                <span>
                  {details?.finishTime ? 
                    details.finishTime.match(/^\d{1,2}:\d{2}(:\d{2})?$/) ?
                      details.finishTime.includes(':') ?
                        details.finishTime
                          .replace(/^(\d{1,2}):(\d{2}):(\d{2})$/, '$1h $2m')  // HH:MM:SS format
                          .replace(/^(\d{1,2}):(\d{2})$/, '$1m $2s')  // MM:SS format
                        : details.finishTime
                      : 'Invalid time'
                    : 'TBD'
                  }
                </span>
              </div>
              <div className="info-item">
                <label>Event Date</label>
                <span>{details?.eventDate ? new Date(details.eventDate).toLocaleDateString() : 'TBD'}</span>
              </div>
            </div>
          </div>
        </div>

        <div className="action-buttons">

          {/* Save Button - Show when user is logged in, event is not saved, and not owned by another user */}
          {user && !isEventSaved && !eventData.userId && (
            <button
              className="save-button"
              onClick={handleSaveToAccount}
              disabled={saveStatus === 'saving'}
            >
              <i className="fas fa-save"></i>
              {saveStatus === 'saving' ? 'Saving...' : 'Save to My Events'}
            </button>
          )}

          {/* Download Button - Show when user owns this event */}
          {isEventSaved && (
            <button
              className="save-button download-button"
              onClick={handleDownload}
            >
              <i className="fas fa-download" />{' '}
              Download Photo
            </button>
          )}

          {/* Share Button - Always visible */}
          <button
            className="share-button"
            onClick={handleShare}
          >
            <i className="fas fa-share-alt" />{' '}
            Share Achievement
          </button>
        </div>

        {saveStatus === 'saved' && (
          <div className="success-message">
            Event saved to your account!
          </div>
        )}
        {saveStatus === 'error' && (
          <div className="error-message">
            Failed to save event. Please try again.
          </div>
        )}
      </div>

      {user?.uid === eventData.userId && (
        <div className="event-actions-section">
          <EventActions
            eventId={eventId}
            isPublic={true}
          />
        </div>
      )}

      <Notes eventId={eventId} isOwner={user?.uid === eventData.userId} />

      {!user && <PromoFooter />}

      {/* Hidden download link */}
      <a
        ref={downloadLinkRef}
        style={{ display: 'none' }}
        aria-hidden="true"
        href={eventData?.imageUrl || '#'}
      >
        Download {eventData?.runnerName}'s photo
      </a>
    </div>
  );
};

export default React.memo(EventPreview);