import React, { useState, useEffect } from 'react';
import { collection, getDocs, query, orderBy, deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { db } from '../../firebase/config';
import './AdminPanel.css';
import { useNavigate } from 'react-router-dom';

const AdminPanel = () => {
  const [events, setEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [stats, setStats] = useState({
    total: 0,
    active: 0,
    archived: 0,
    completed: 0
  });
  const [deleteConfirm, setDeleteConfirm] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [editingEvent, setEditingEvent] = useState(null);
  const [editForm, setEditForm] = useState({
    eventName: '',
    runnerName: '',
    eventDate: '',
    status: ''
  });
  const navigate = useNavigate();

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const eventsQuery = query(
          collection(db, 'events'),
          orderBy('createdAt', 'desc')
        );
        const eventsSnapshot = await getDocs(eventsQuery);
        const eventsData = eventsSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        
        // Calculate stats
        const newStats = eventsData.reduce((acc, event) => {
          acc.total++;
          acc[event.status]++;
          return acc;
        }, { total: 0, active: 0, archived: 0, completed: 0 });
        
        setStats(newStats);
        setEvents(eventsData);
      } catch (error) {
        console.error('Error fetching events:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchEvents();
  }, []);

  const handleDelete = async (eventId) => {
    setIsDeleting(true);
    const eventToDelete = events.find(e => e.id === eventId);
    try {
      const eventRef = doc(db, 'events', eventId);
      await deleteDoc(eventRef);
      setEvents(events.filter(event => event.id !== eventId));
      setDeleteConfirm(null);
      setSuccessMessage(`Successfully deleted event "${eventToDelete.eventName}" for ${eventToDelete.runnerName}`);
      // Scroll to top smoothly
      window.scrollTo({ top: 0, behavior: 'smooth' });
      // Clear success message after 5 seconds
      setTimeout(() => setSuccessMessage(null), 5000);
    } catch (err) {
      console.error('Error deleting event:', err);
      setError('Failed to delete event. Please try again.');
    } finally {
      setIsDeleting(false);
    }
  };

  const openEditModal = (event) => {
    setEditingEvent(event);
    setEditForm({
      eventName: event.eventName,
      runnerName: event.runnerName,
      eventDate: event.eventDate.split('T')[0],
      status: event.status
    });
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    try {
      const eventRef = doc(db, 'events', editingEvent.id);
      await updateDoc(eventRef, editForm);
      
      // Update local state
      setEvents(events.map(event => 
        event.id === editingEvent.id ? { ...event, ...editForm } : event
      ));
      
      setEditingEvent(null);
      setSuccessMessage(`Successfully updated event "${editForm.eventName}"`);
      window.scrollTo({ top: 0, behavior: 'smooth' });
      setTimeout(() => setSuccessMessage(null), 5000);
    } catch (err) {
      console.error('Error updating event:', err);
      setError('Failed to update event. Please try again.');
    }
  };

  const handleVisibilityToggle = async (event) => {
    try {
      const eventRef = doc(db, 'events', event.id);
      const newIsPublic = !event.isPublic;
      
      await updateDoc(eventRef, { isPublic: newIsPublic });
      
      // Update local state
      setEvents(events.map(e => 
        e.id === event.id ? { ...e, isPublic: newIsPublic } : e
      ));
      
      setSuccessMessage(`Event "${event.eventName}" is now ${newIsPublic ? 'public' : 'private'}`);
      window.scrollTo({ top: 0, behavior: 'smooth' });
      setTimeout(() => setSuccessMessage(null), 5000);
    } catch (err) {
      console.error('Error updating event visibility:', err);
      setError('Failed to update event visibility. Please try again.');
    }
  };

  return (
    <div className="admin-panel">
      <h1>Admin Panel</h1>
      
      {successMessage && (
        <div className="success-message-container">
          <div className="success-message">{successMessage}</div>
        </div>
      )}
      {error && <div className="error-message">{error}</div>}
      
      <div className="stats-cards">
        <div className="stat-card total">
          <i className="fas fa-calendar-alt"></i>
          <div className="stat-content">
            <span className="stat-value">{stats.total}</span>
            <span className="stat-label">Total Events</span>
          </div>
        </div>
        <div className="stat-card active">
          <i className="fas fa-running"></i>
          <div className="stat-content">
            <span className="stat-value">{stats.active}</span>
            <span className="stat-label">Active Events</span>
          </div>
        </div>
        <div className="stat-card completed">
          <i className="fas fa-flag-checkered"></i>
          <div className="stat-content">
            <span className="stat-value">{stats.completed}</span>
            <span className="stat-label">Completed Events</span>
          </div>
        </div>
        <div className="stat-card archived">
          <i className="fas fa-archive"></i>
          <div className="stat-content">
            <span className="stat-value">{stats.archived}</span>
            <span className="stat-label">Archived Events</span>
          </div>
        </div>
      </div>

      <section className="events-section">
        <h2>Events Management</h2>
        {isLoading ? (
          <div className="loading">Loading events...</div>
        ) : (
          <div className="events-table-container">
            <table className="events-table">
              <thead>
                <tr>
                  <th>Event Date</th>
                  <th>Event Name</th>
                  <th>Runner</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {events.map(event => (
                  <tr key={event.id}>
                    <td>
                      <span className="event-date">
                        {new Date(event.eventDate).toLocaleDateString('en-US', {
                          year: '2-digit',
                          month: 'short',
                          day: 'numeric'
                        })}
                      </span>
                    </td>
                    <td>{event.eventName}</td>
                    <td>{event.runnerName}</td>
                    <td>
                      <span className={`status-badge ${event.status}`}>
                        {event.status}
                      </span>
                    </td>
                    <td>
                      <div className="action-buttons">
                        <button 
                          className={`action-button visibility ${event.isPublic ? 'public' : 'private'}`}
                          title={`Make ${event.isPublic ? 'Private' : 'Public'}`}
                          onClick={() => handleVisibilityToggle(event)}
                        >
                          <i className={`fas fa-${event.isPublic ? 'eye' : 'eye-slash'}`}></i>
                        </button>
                        <button 
                          className="action-button view"
                          title="View Details"
                          onClick={() => window.open(`#/event/${event.id}`, '_blank')}
                        >
                          <i className="fas fa-eye"></i>
                        </button>
                        <button 
                          className="action-button edit"
                          title="Edit Event"
                          onClick={() => navigate(`/event/${event.id}/edit`)}
                        >
                          <i className="fas fa-edit"></i>
                        </button>
                        <button 
                          className="action-button delete"
                          title="Delete Event"
                          onClick={() => setDeleteConfirm(event.id)}
                        >
                          <i className="fas fa-trash"></i>
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </section>

      {/* Add modal markup */}
      {deleteConfirm && (
        <div className="delete-modal">
          <div className="delete-modal-content">
            <h2>Delete Event</h2>
            <p>Are you sure you want to permanently delete this event? This action cannot be undone.</p>
            <div className="delete-modal-buttons">
              <button 
                className="cancel-button"
                onClick={() => setDeleteConfirm(null)}
                disabled={isDeleting}
              >
                Cancel
              </button>
              <button 
                className="confirm-delete-button"
                onClick={() => handleDelete(deleteConfirm)}
                disabled={isDeleting}
              >
                {isDeleting ? 'Deleting...' : 'Delete'}
              </button>
            </div>
          </div>
        </div>
      )}

      {editingEvent && (
        <div className="edit-modal">
          <div className="edit-modal-content">
            <h2>Edit Event</h2>
            <form onSubmit={handleEditSubmit}>
              <div className="form-group">
                <label>Event Name</label>
                <input
                  type="text"
                  value={editForm.eventName}
                  onChange={(e) => setEditForm({...editForm, eventName: e.target.value})}
                  required
                />
              </div>
              <div className="form-group">
                <label>Runner Name</label>
                <input
                  type="text"
                  value={editForm.runnerName}
                  onChange={(e) => setEditForm({...editForm, runnerName: e.target.value})}
                  required
                />
              </div>
              <div className="form-group">
                <label>Event Date</label>
                <input
                  type="date"
                  value={editForm.eventDate}
                  onChange={(e) => setEditForm({...editForm, eventDate: e.target.value})}
                  required
                />
              </div>
              <div className="form-group">
                <label>Status</label>
                <select
                  value={editForm.status}
                  onChange={(e) => setEditForm({...editForm, status: e.target.value})}
                  required
                >
                  <option value="active">Active</option>
                  <option value="completed">Completed</option>
                  <option value="archived">Archived</option>
                </select>
              </div>
              <div className="modal-buttons">
                <button type="button" onClick={() => setEditingEvent(null)}>
                  Cancel
                </button>
                <button type="submit">
                  Save Changes
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminPanel; 