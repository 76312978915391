import React from 'react';
import Hero from '../Hero';
import Features from '../Features';
import StartFlow from '../StartFlow';
import FAQ from '../FAQ';
import QuickDemo from '../QuickDemo';
import './LandingPage.css';

const LandingPage = () => {
  return (
    <div className="landing-container">
      <Hero />
      <StartFlow />
      <Features />
      <FAQ />
      <QuickDemo />
    </div>
  );
};

export default LandingPage; 