import React, { useState, useEffect } from 'react';
import { useAuth } from '../../context/AuthContext';
import './AccountSettings.css';
import { getAuth } from 'firebase/auth';
import { doc, updateDoc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase/config';

const AccountSettings = () => {
  const { user } = useAuth();
  const [role, setRole] = useState('');
  const [subscriptionType, setSubscriptionType] = useState('');
  const [hasChanges, setHasChanges] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  
  const subscriptionOptions = [
    { value: 'free', label: 'Free Tier', features: ['Basic customization', 'Digital downloads'] },
    { value: 'premium', label: 'Premium', features: ['Advanced customization', 'Unlimited downloads', 'Print ordering'] },
    { value: 'pro', label: 'Professional', features: ['All Premium features', 'Bulk creation', 'Analytics'] }
  ];

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const auth = getAuth();
        const userId = auth.currentUser?.uid;

        if (!userId) {
          throw new Error('No user logged in');
        }

        const userRef = doc(db, 'users', userId);
        const userSnap = await getDoc(userRef);

        if (userSnap.exists()) {
          const userData = userSnap.data();
          setRole(userData.role || 'user');
          setSubscriptionType(userData.subscriptionType || 'free');
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
        alert('Failed to load user settings');
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserData();
  }, []);

  const handleRoleChange = (e) => {
    setRole(e.target.value);
    setHasChanges(true);
  };

  const handleSubscriptionChange = (e) => {
    setSubscriptionType(e.target.value);
    setHasChanges(true);
  };

  const handleUpdate = async () => {
    try {
      setIsUpdating(true);
      const auth = getAuth();
      const userId = auth.currentUser?.uid;

      if (!userId) {
        throw new Error('No user logged in');
      }

      const userRef = doc(db, 'users', userId);
      await updateDoc(userRef, {
        role,
        subscriptionType,
        updatedAt: new Date().toISOString()
      });

      setHasChanges(false);
      alert('Account settings updated successfully!');
    } catch (error) {
      console.error('Error updating account:', error);
      alert('Failed to update account settings. Please try again.');
    } finally {
      setIsUpdating(false);
    }
  };

  if (isLoading) {
    return (
      <div className="loading-state">
        <i className="fas fa-spinner fa-spin"></i>
        Loading account settings...
      </div>
    );
  }

  if (!user) return null;

  return (
    <div className="account-settings-container">
      <header className="account-header">
        <h1>Account Settings</h1>
      </header>

      <div className="account-content">
        <div className="profile-section">
          <div className="profile-photo-container">
            {user.photoURL ? (
              <img src={user.photoURL} alt={user.displayName} className="profile-photo" />
            ) : (
              <div className="profile-photo-placeholder">
                <i className="fas fa-user-circle"></i>
              </div>
            )}
          </div>
          <div className="profile-info">
            <h2>{user.displayName}</h2>
            <p className="email">{user.email}</p>
            <p className="joined-date">
              Joined {new Date(user.metadata.creationTime).toLocaleDateString()}
            </p>
          </div>
        </div>

        <div className="account-details">
          <div className="detail-section">
            <h3>Account Information</h3>
            <div className="detail-grid">
              <div className="detail-item">
                <label>Name</label>
                <span>{user.displayName}</span>
              </div>
              <div className="detail-item">
                <label>Email</label>
                <span>{user.email}</span>
              </div>
              <div className="detail-item">
                <label>Account Type</label>
                <span>Google Account</span>
              </div>
              <div className="detail-item">
                <label>Last Sign In</label>
                <span>{new Date(user.metadata.lastSignInTime).toLocaleString()}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="profile-controls">
        <h2>Profile Settings</h2>
        
        <div className="settings-group">
          <h3>Role</h3>
          <div className="current-role">
            Current Role: <span className="highlight">{role}</span>
          </div>
          <select 
            value={role} 
            onChange={handleRoleChange}
            className="settings-select"
          >
            <option value="user">User</option>
            <option value="creator">Creator</option>
            <option value="admin">Admin</option>
          </select>
        </div>

        <div className="settings-group">
          <h3>Subscription</h3>
          <div className="current-subscription">
            Current Plan: <span className="highlight">{subscriptionType}</span>
          </div>
          {subscriptionOptions.map((option) => (
            <div key={option.value} className="subscription-option">
              <input
                type="radio"
                id={option.value}
                name="subscription"
                value={option.value}
                checked={subscriptionType === option.value}
                onChange={handleSubscriptionChange}
              />
              <label htmlFor={option.value}>
                <span className="subscription-label">{option.label}</span>
                <ul className="feature-list">
                  {option.features.map((feature, index) => (
                    <li key={index}>{feature}</li>
                  ))}
                </ul>
              </label>
            </div>
          ))}
        </div>

        {hasChanges && (
          <div className="update-button-container">
            <button 
              className={`update-button ${isUpdating ? 'updating' : ''}`}
              onClick={handleUpdate}
              disabled={isUpdating}
            >
              {isUpdating ? 'Updating...' : 'Update Account'}
            </button>
          </div>
        )}
      </section>
    </div>
  );
};

export default AccountSettings; 