import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../firebase/config';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { storage } from '../../firebase/config';
import './EditEvent.css';
import { collection, setDoc } from 'firebase/firestore';

const EditEvent = () => {
  const { eventId } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [eventData, setEventData] = useState({
    runnerName: '',
    eventName: '',
    eventDate: '',
    eventTime: '',
    location: '',
    finishTime: '',
    status: '',
    photoId: '',
    imageUrl: ''
  });
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    const fetchEvent = async () => {
      try {
        const eventDoc = await getDoc(doc(db, 'events', eventId));
        if (eventDoc.exists()) {
          const eventDetails = eventDoc.data();
          
          if (eventDetails.photoId) {
            const photoDoc = await getDoc(doc(db, 'photos', eventDetails.photoId));
            if (photoDoc.exists()) {
              eventDetails.imageUrl = photoDoc.data().imageUrl;
            }
          }
          
          setEventData(eventDetails);
        } else {
          setError('Event not found');
        }
      } catch (err) {
        setError('Error fetching event');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchEvent();
  }, [eventId]);

  const handleChange = (e) => {
    setEventData({
      ...eventData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await updateDoc(doc(db, 'events', eventId), eventData);
      navigate(`/event/${eventId}`);
    } catch (err) {
      setError('Error updating event');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handlePhotoUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    setUploading(true);
    try {
      const storageRef = ref(storage, `event-photos/${eventId}`);
      await uploadBytes(storageRef, file);
      const imageUrl = await getDownloadURL(storageRef);
      
      const photoRef = doc(collection(db, 'photos'));
      await setDoc(photoRef, { imageUrl });
      
      const photoId = photoRef.id;
      setEventData(prev => ({ ...prev, photoId, imageUrl }));
      await updateDoc(doc(db, 'events', eventId), { photoId });
    } catch (err) {
      setError('Error uploading photo');
      console.error(err);
    } finally {
      setUploading(false);
    }
  };

  if (loading) return <div className="loading-text">Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="edit-event-container">
      <h1>Edit Event</h1>
      <form onSubmit={handleSubmit} className="edit-form">
        <div className="photo-section">
          {eventData.imageUrl && (
            <img 
              src={eventData.imageUrl}
              alt="Event" 
              className="event-photo"
            />
          )}
          <div className="photo-upload">
            <label htmlFor="photo" className="photo-label">
              {uploading ? 'Uploading...' : 'Change Photo'}
            </label>
            <input
              type="file"
              id="photo"
              accept="image/*"
              onChange={handlePhotoUpload}
              disabled={uploading}
              className="photo-input"
            />
          </div>
        </div>

        <div className="form-group">
          <label htmlFor="runnerName">Runner Name</label>
          <input
            type="text"
            id="runnerName"
            name="runnerName"
            value={eventData.runnerName}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="eventName">Event Name</label>
          <input
            type="text"
            id="eventName"
            name="eventName"
            value={eventData.eventName}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="eventDate">Event Date</label>
          <input
            type="date"
            id="eventDate"
            name="eventDate"
            value={eventData.eventDate}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="eventTime">Event Time</label>
          <input
            type="time"
            id="eventTime"
            name="eventTime"
            value={eventData.eventTime}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="location">Location</label>
          <input
            type="text"
            id="location"
            name="location"
            value={eventData.location}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="finishTime">Finish Time</label>
          <input
            type="text"
            id="finishTime"
            name="finishTime"
            value={eventData.finishTime}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="status">Status</label>
          <select
            id="status"
            name="status"
            value={eventData.status}
            onChange={handleChange}
          >
            <option value="pending">Pending</option>
            <option value="active">Active</option>
            <option value="archived">Archived</option>
          </select>
        </div>

        <div className="button-group">
          <button type="button" onClick={() => navigate(-1)}>Cancel</button>
          <button type="submit" disabled={loading}>
            {loading ? 'Saving...' : 'Save Changes'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditEvent; 