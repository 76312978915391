import React, { useState } from 'react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../../firebase/config';
import './QuickDemo.css';

const QuickDemo = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleDemoClick = async () => {
    setIsLoading(true);
    setError(null);
    
    try {
      const eventsQuery = query(
        collection(db, 'events'),
        where('isPublic', '==', true)
      );
      
      const snapshot = await getDocs(eventsQuery);
      const publicEvents = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      
      if (publicEvents.length === 0) {
        setError('No demo events available at the moment.');
        return;
      }
      
      const randomEvent = publicEvents[Math.floor(Math.random() * publicEvents.length)];
      window.location.href = `#/event/${randomEvent.id}`;
      
    } catch (err) {
      console.error('Error fetching demo event:', err);
      setError('Failed to load demo. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <section className="quick-demo">
      <div className="demo-content">
        <h2>See It In Action</h2>
        <p>Want to see how it works? Check out a live event demo.</p>
        <button 
          className="demo-button"
          onClick={handleDemoClick}
          disabled={isLoading}
        >
          {isLoading ? 'Loading...' : 'View Demo'}
        </button>
        {error && <p className="demo-error">{error}</p>}
      </div>
    </section>
  );
};

export default QuickDemo; 