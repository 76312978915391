import React from 'react';
import './Features.css';

const Features = () => {
  return (
    <section className="features">
      <div className="feature-card">
        <i className="fas fa-camera"></i>
        <h3>Create Beautiful Memories</h3>
        <p>Design custom photo gifts for runners of any distance - from fun runs to ultramarathons</p>
      </div>
      <div className="feature-card">
        <i className="fas fa-gift"></i>
        <h3>Personalized Gifts</h3>
        <p>Personalize with inspiring messages, race stats, and custom decorations to celebrate their achievement</p>
      </div>
      <div className="feature-card">
        <i className="fas fa-share"></i>
        <h3>Easy Sharing</h3>
        <p>Download digitally, share via link, or order as printed memorabilia</p>
      </div>
    </section>
  );
};

export default Features; 