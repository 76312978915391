import React, { useState, useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './context/AuthContext';
import Navigation from './components/Navigation';
import LandingPage from './components/LandingPage';
import EventDetails from './components/EventDetails';
import PhotoUpload from './components/PhotoUpload';
import EventPreview from './components/EventPreview';
import UserEvents from './components/UserEvents';
import AccountSettings from './components/AccountSettings';
import MyArchivedEvents from './components/MyArchivedEvents';
import Footer from './components/Footer';
import AdminPanel from './components/AdminPanel';
import Error404 from './components/Error404';
import EditEvent from './components/EditEvent';
import './App.css';
import { getDoc, doc } from 'firebase/firestore';
import { db } from './firebase/config';

// Protected Route wrapper component
const AdminRoute = ({ children }) => {
  const { user } = useAuth();
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkAdminStatus = async () => {
      if (user) {
        const userRef = doc(db, 'users', user.uid);
        const userSnap = await getDoc(userRef);
        if (userSnap.exists()) {
          setIsAdmin(userSnap.data().role === 'admin');
        }
      }
      setLoading(false);
    };
    
    checkAdminStatus();
  }, [user]);

  if (loading) return null;
  
  return isAdmin ? children : <Navigate to="/" />;
};

function App() {
  return (
    <AuthProvider>
      <Navigation />
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/events/new" element={<EventDetails />} />
        <Route path="/event-details" element={<EventDetails />} />
        <Route path="/photo-upload" element={<PhotoUpload />} />
        <Route path="/event/:eventId/*" element={<EventPreview />} />
        <Route path="/my-events" element={<UserEvents />} />
        <Route path="/account" element={<AccountSettings />} />
        <Route path="/my-events/archived" element={<MyArchivedEvents />} />
        <Route path="/event/:eventId/edit" element={<EditEvent />} />
        <Route 
          path="/admin" 
          element={
            <AdminRoute>
              <AdminPanel />
            </AdminRoute>
          } 
        />
        <Route 
          path="/event/:eventId/edit" 
          element={
            <AdminRoute>
              <EditEvent />
            </AdminRoute>
          } 
        />
         <Route path="*" element={<Error404 />} />
      </Routes>
      <Footer />
    </AuthProvider>
  );
}

export default App; 