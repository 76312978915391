import React from 'react';
import { Link } from 'react-router-dom';
import './PromoFooter.css';

const PromoFooter = () => {
  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <div className="promo-footer">
      <div className="promo-content">
        <h3>Transform Race Moments into Lasting Memories</h3>
        <p>
          Transform your race photos into something special. Whether it's a stunning 
          digital album for yourself or a thoughtful gift for a fellow runner, we'll 
          help you capture the pride of every finish line moment.
        </p>
        <div className="promo-actions">
          <Link to="/" className="promo-button primary" onClick={handleClick}>
            Create Your Memory
          </Link>
          <Link to="/" className="promo-button secondary" onClick={handleClick}>
            Explore Gift Options
          </Link>
        </div>
      </div>
    </div>
  );
};

export default PromoFooter; 